@import './base/fonts.scss';
@import './base/reset.css';
@import './base/variables.scss';
@import './base/global.scss';
@import './base/grid/bootstrap-grid.scss';

@import './overrides/modal.scss';
@import './overrides/slick-slider.scss';
@import './overrides/accordion.scss';

@import './modules/Accordion.scss';
@import './modules/Assistant.scss';
@import './modules/Button.scss';
@import './modules/Card.scss';
@import './modules/FaqContact.scss';
@import './modules/Field.scss';
@import './modules/FirstBlock.scss';
@import './modules/Footer.scss';
@import './modules/Form.scss';
@import './modules/Header.scss';
@import './modules/IconBlock.scss';
@import './modules/Info.scss';
@import './modules/Logo.scss';
@import './modules/Modal.scss';
@import './modules/Page.scss';
@import './modules/Provision.scss';
@import './modules/Pricing.scss';
@import './modules/Quote.scss';
@import './modules/ScreenReaderOnly.scss';
@import './modules/Section.scss';
@import './modules/SiteLanguage.scss';
@import './modules/Slider.scss';
@import './modules/Subscribe.scss';
@import './modules/Typography.scss';
@import './modules/Beneficiary.scss';

@import './base/utilities.scss';
