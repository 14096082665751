.FaqContact {
  background-image: url('../../images/faq-contact-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 295px;
  display: inline-flex;
  flex-direction: column;
  padding: 52px 0 83px;

  &-button {
    padding: 25px 0 0;
  }
}
