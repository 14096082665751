.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}

.modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal-dialog {
  left: 50%;
  max-height: 80vh;
  max-width: 700px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
