.beneficiary-advertise-pannel {
  background-color: $amazon;
}

.beneficiary-panel-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.beneficiary-panel-link {
  color: $white;
  text-decoration: none;
  outline: none;
}

.beneficiary-left-section-image {
  margin-left: 70px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.beneficiary-left-section-text {
  margin-left: 100px;
  margin-top: 60px;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}
