.Page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-content {
    flex: 1;

    &--angle {
      background-color: $white;
      background-image: url('../../images/angle.png');
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
}
