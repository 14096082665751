.Subscribe {
  &-image {
    height: 100%;
    max-width: 357px;
    position: relative;
    width: 100%;
  }

  &-imageItem {
    bottom: 0;
    position: absolute;
    width: 100%;

    @include media-breakpoint-down(sm) {
      display: block;
      position: static;
    }
  }

  &-info {
    @include media-breakpoint-down(md) {
      padding: 0 20px;
    }
  }
}
