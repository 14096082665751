@tailwind base;
@tailwind components;
@tailwind utilities;

@import "twStyles/card.css";
@import "twStyles/typography.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.theme {
  /* COLORS */
  --color-alpha-100: #edf5f1;
  --color-alpha-150: #e0eae4;
  --color-alpha-200: #cedfd5;
  --color-alpha-600: #40946a;
  --color-alpha-700: #056837;
  --color-beta-50: #fbfbfb;
  --color-beta-100: #f4f4f4;
  --color-beta-200: #c1c1c1;
  --color-beta-400: #9da1a4;
  --color-beta-500: #787b7d;
  --color-beta-700: #413d45;
  --color-beta-900: #000;
  --color-gamma-400: #ffffff; /* giving it a number shade bc other theme could change it to e.g. purple & add shades*/
  --color-delta-100: #e7f0f6;
  --color-delta-200: #dbe9f3;
  --color-delta-300: #b9d6ec;
  --color-delta-400: #80a3be;
  --color-delta-500: #68a4d4;
  --color-delta-700: #2974b2;
  --color-delta-900: #093e62;
  --color-epsilon-100: #fbe8eb;
  --color-epsilon-400: #f8a0b0;
  --color-epsilon-600: #e23e5c;
  --color-zeta-600: #ff7c1d;
  --color-eta-100: #fff8df;
  --color-eta-200: #fbe6d0;
  --color-eta-300: #f2e5b7;

  /* BORDER RADIUS */
  --border-radius: 12px;
  --border-radius-sm: 6px;
  --border-radius-xs: 2px;
  /* FONT SIZE */
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 22px;
  /* LINE HEIGHT */
  --line-height-xs: 12px;
  --line-height-sm: 16px;
  --line-height-md: 18px;
  --line-height-lg: 22px;
  --line-height-xl: 28px;
  /* FONT WEIGHT */
  --font-weight-thin: 200;
  --font-weight-normal: normal;
  --font-weight-semibold: 600;
  /* FONT FAMILY */
  --font-family-primary: Helvetica;
  --font-family-secondary: Montserrat;
  /* BOX SHADOW */
  --box-shadow: 0px 4px 10px rgba(65, 61, 69, 0.1);
  --box-shadow-lg: 0px 4px 20px rgba(65, 61, 69, 0.1);
  /* BREAKPOINTS */
  --breakpoint-xs: 0;
  --breakpoint-sm: "576px";
  --breakpoint-md: "768px";
  --breakpoint-lg: "992px";
  --breakpoint-xl: "1200px";
}
