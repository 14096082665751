.Modal {
  padding: 60px 0 60px 40px;

  @include media-breakpoint-down(md) {
    padding: 60px 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }

  &-close {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  &-closeImage {
    filter: invert(66%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(87%) contrast(84%);
    pointer-events: none;
  }

  &-description {
    padding: 16px 0 24px;
  }

  &-decoration {
    flex: 0 0 250px;
    margin: 0 0 0 50px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
