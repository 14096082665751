.Typography {
  &--title {
    font-family: Montserrat;
    font-size: 22px;
    line-height: 28px;
  }

  &--subTitle {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 22px;
  }

  &--main {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 18px;
  }

  &--secondary {
    font-family: Helvetica;
    font-size: 12px;
    line-height: 16px;
  }

  &--landingMainTitle {
    font-family: Montserrat;
    font-size: 42px;
    line-height: 58px;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &--landingTitle {
    font-family: Montserrat;
    font-size: 36px;
    line-height: 58px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 34px;
    }
  }

  &--landingTitleLower {
    font-family: Montserrat;
    font-size: 36px;
    line-height: 44px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 34px;
    }
  }

  &--cardTitle {
    font-family: Montserrat;
    font-size: 32px;
    line-height: 50px;

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  &--landingQuote {
    font-family: Montserrat;
    font-size: 24px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &--landingSubTitle {
    font-family: Montserrat;
    font-size: 22px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &--landingListItem {
    font-family: Helvetica;
    font-size: 22px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &--landingMain {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 24px;
  }

  &--landingMainMd {
    @include media-breakpoint-down(md) {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--normalText {
    font-family: Helvetica;
    font-size: 20px;
    line-height: 23px;
  }
}
