@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat/Montserrat-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
