.Quote {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &-image {
    height: 160px;
    margin: 0 40px 0 0;
    width: 160px;

    @include media-breakpoint-down(md) {
      align-self: center;
      margin: 0;
    }
  }

  &-author {
    display: block;
    padding: 23px 0 0;
  }

  &-description {
    width: calc(100% - 200px);

    @include media-breakpoint-down(md) {
      padding: 30px 0 0;
      width: 100%;
    }
  }
}
