@for $width from 0 through 12 {
  .u-pb-#{$width * 10} {
    padding-bottom: #{$width * 10}#{'px'};
  }

  .u-pt-#{$width * 10} {
    padding-top: #{$width * 10}#{'px'};
  }
}

@for $width from 0 through 4 {
  .u-pb-md-#{$width * 10} {
    @include media-breakpoint-down(md) {
      padding-bottom: #{$width * 10}#{'px'};
    }
  }

  .u-pt-md-#{$width * 10} {
    @include media-breakpoint-down(md) {
      padding-top: #{$width * 10}#{'px'};
    }
  }
}

.u {
  &-color {
    &-amazon {
      color: $amazon;
    }

    &-cerise-red {
      color: $cerise-red;
    }

    &-gray {
      color: $gray;
    }

    &-jelly-bean {
      color: $jelly-bean;
    }

    &-nepal {
      color: $nepal;
    }

    &-persian-red {
      color: $persian-red;
    }

    &-tarawera {
      color: $tarawera;
    }

    &-viridian {
      color: $viridian;
    }

    &-white {
      color: $white;
    }

    &-ship-gray {
      color: $ship-gray;
    }
  }

  &-pt-75 {
    padding-top: 75px;
  }

  &-pb-6 {
    padding-bottom: 6px;
  }

  &-pb-16 {
    padding-bottom: 16px;
  }

  &-pb-24 {
    padding-bottom: 24px;
  }

  &-pb-75 {
    padding-bottom: 75px;
  }

  &-pl-10 {
    padding-left: 10px;
  }

  &-pr-10 {
    padding-right: 10px;
  }

  &-pr-50 {
    padding-right: 50px;
  }

  &-pr-60 {
    padding-right: 60px;
  }

  &-pr-80 {
    padding-right: 80px;
  }

  &-pr-md-0 {
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }

  &-ml-4 {
    margin-left: 4px;
  }

  &-ml-10 {
    margin-left: 10px;
  }

  &-ml-10 {
    margin-left: 10px;
  }

  &-ml-20 {
    margin-left: 20px;
  }

  &-ml-40 {
    margin-left: 40px;
  }

  &-ml-70 {
    margin-left: 70px;
  }

  &-mr-10 {
    margin-right: 10px;
  }

  &-mr-15 {
    margin-right: 15px;
  }

  &-mr-20 {
    margin-right: 20px;
  }

  &-mr-30 {
    margin-right: 30px;
  }

  &-mr-60 {
    margin-bottom: 60px;
  }

  &-weight {
    &-400 {
      font-weight: 400;
    }

    &-600 {
      font-weight: 600;
    }

    &-700 {
      font-weight: 700;
    }
  }

  &-w {
    &-100 {
      width: 100%;
    }
  }

  &-h {
    &-100 {
      height: 100%;
    }
  }

  &-noUnderline {
    text-decoration: none;
  }

  &-noOutline {
    outline: none;
  }

  &-noPadding {
    padding: 0;
  }

  &-pointer {
    cursor: pointer;
  }

  &-text {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-sm-center {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }

  &-flex-1 {
    flex: 1;
  }

  &-preline {
    white-space: pre-line;
  }

  &-overflow {
    &-hidden {
      overflow: hidden;
    }
  }

  &-word-break {
    word-break: break-all;
  }
}
