.Slider {
  position: relative;

  &-navigation {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -18px;
  }
}
