.Section {
  &--alabaster {
    background-color: $alabaster;
  }

  &--black-squeeze {
    background-color: $black-squeeze;
  }

  &--gin2 {
    background-color: $gin2;
  }

  &--white {
    background-color: $white;
  }

  &-content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 10px;
  }

  &--greenNoice {
    background-image: url('../../images/faq-bg.png');
  }
}
