html {
  background-color: $gin2;
  box-sizing: border-box;
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: 1px solid $spindle;
}

body {
  color: $ship-gray;
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  min-height: 100%;
  font-family: Helvetica;
}

textarea {
  resize: vertical;
}

strong {
  font-weight: bold;
}
