.IconBlock {
  padding: 0 0 80px;

  @include media-breakpoint-down(md) {
    padding: 0 0 30px;
  }

  &-header {
    align-items: center;
    display: flex;
  }

  &-image {
    padding: 0 30px 0 0;
    width: 95px;

    @include media-breakpoint-down(md) {
      padding: 0 20px 0 0;
      width: initial;
    }
  }

  &-content {
    padding: 7px 0 0 0;

    @include media-breakpoint-down(md) {
      text-align: justify;
    }
  }
}
