.slick-dots {
  bottom: initial;

  .Slider-navigation {
    li {
      display: flex;
      margin: 0 8px 0 0;
      height: initial;
      width: initial;

      &.slick-active {
        button {
          height: 10px;
          opacity: 1;
          width: 10px;
        }
      }
    }

    button {
      background-color: $viridian;
      border-radius: 50%;
      border: 0;
      height: 6px;
      opacity: 0.4;
      padding: 0;
      width: 6px;

      &::before {
        display: none;
      }
    }
  }
}

.slick-arrow {
  background-color: $white;
  background: url(../../images/chevrone-down.svg) no-repeat center center;
  background-size: 21px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(130, 134, 125, 0.1);
  height: 40px;
  width: 40px;

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &::before {
    display: none;
  }

  &:hover {
    background: url(../../images/chevrone-down.svg) no-repeat center;
    background-size: 21px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  }

  &:active {
    background: url(../../images/chevrone-down.svg) no-repeat center;
    background-size: 21px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    background: url(../../images/chevrone-down.svg) no-repeat center;
    background-size: 21px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  }

  &.slick-next {
    right: -110px;
    transform: translate(0, -50%) rotate(-90deg);
  }

  &.slick-prev {
    left: -110px;
    transform: translate(0, -50%) rotate(90deg);
  }
}
