.Card {
  border-radius: 12px;

  &--hidden {
    overflow: hidden;
  }

  &--black-squeeze {
    background: $black-squeeze;
  }

  &--gin {
    background: $gin;
  }

  &--paris-white {
    background: $paris-white;
  }

  &--tarawera {
    background: $tarawera;
  }

  &--white {
    background: $white;
  }

  &--small {
    border-radius: 30px;
    height: 100%;
    padding: 40px 38px 30px;

    @include media-breakpoint-down(md) {
      padding: 30px 20px;
    }
  }
}
