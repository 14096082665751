.SiteLanguage {
  display: flex;
  padding: 0 0 40px;

  &--landing {
    padding: 0;

    .SiteLanguage-button {
      padding: 9px;
    }
  }

  &-button {
    background: transparent;
    border: 0;
    border-radius: 12px;
    padding: 10px;

    &.isActive {
      background-color: $gin;
    }
  }

  &-image {
    border-radius: 4px;
    display: block;
  }
}
