.Accordion {
  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-titleText {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;

    @include media-breakpoint-down(md) {
      font-size: 23px;
    }
  }

  &-item {
    border-top: 1px solid $seashell;
    padding: 16px 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid $seashell;
    }
  }

  &-chevrone {
    left: -6px;
    position: relative;

    &--hide {
      left: -8px;
      top: -2px;
      transform: rotate(180deg);
    }
  }
}
