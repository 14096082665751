.Info {
  padding: 80px 0 100px;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }

  &--bottom {
    padding: 130px 0 120px;

    @include media-breakpoint-down(md) {
      padding: 30px 0;
    }
  }

  &-listImage {
    height: 90px;
    &-w-100 {
      width: 100px;
    }
  }

  &-title {
    @include media-breakpoint-down(md) {
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 700;
      line-height: 34px;
    }
  }
}
